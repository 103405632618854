import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import Script from 'next/script'

import {
  TypeUserState,
  dataLayerEventProductClick,
  getValueOrDefault,
  pushToDataLayer,
  sendBeaconData,
  useUser
} from '@k_frontend/core'
import axios from 'axios'
import PageCatalog from 'components/PageCatalog'
import PageCatalogEmpty from 'components/PageCatalogEmpty'
import PageHead from 'components/PageHead'
import { useBannerMonetizaDepartments } from 'hooks/useBannerMonetiza'
import { useCatalog } from 'hooks/useCatalog'
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect'
import { useTimestamp as useTimestampHook } from 'hooks/useTimestamp'
import { useQuery } from 'react-query'
import CatalogService from 'services/catalog'
import Categories from 'services/categories'
import {
  getBannersCategories,
  getParamsFromQueryString
} from 'services/listing'
import { SponsoredProductsService } from 'services/sponsoredProducts'
import { BACKEND_TIME_STAMP } from 'settings/kernel'
import theme from 'theme'
import {
  type Catalog as CatalogType,
  FilterType,
  type Product
} from 'types/catalog'
import { base64Decode } from 'utils/base64'
import { formatCategoryName } from 'utils/formatCategoryName'
import { formatSendBeaconData } from 'utils/formatSendBeaconData'
import { formatSponsoredProductsHeadersParams } from 'utils/formatSponsoredProductsHeadersParams'
import { hasSponsoredProductsByParams } from 'utils/hasSponsoredProductsByParams'
import { axiosError } from 'utils/http/error'
import { getGtmSchemaCatalogo } from 'utils/schema'
import { getSponsoredProductsVariation } from 'utils/sponsoredProductsVariation'
import { shallow } from 'zustand/shallow'

const useUserSelector = (state: TypeUserState) => ({
  clientId: state.clientId,
  session: state.session,
  validSession: state.validSession,
  isPrime: state.isPrime
})

export default function Categorias({ data }) {
  data = JSON.parse(data)
  const {
    type,
    slug,
    seo,
    catalogServer,
    categories,
    breadcrumbServer = [],
    pagelist,
    cookieIsMobile,
    banners
  } = data

  const sponsoredProductsService = new SponsoredProductsService()

  const {
    init,
    params,
    setParams,
    setLoading,
    setCatalog,
    catalog,
    filterPressed
  } = useCatalog()

  const { clientId, session, validSession, isPrime } = useUser(
    useUserSelector,
    shallow
  )
  const useTimestamp = useTimestampHook()
  const router = useRouter()
  const [noResults, setNoResults] = useState(!catalogServer?.data)
  const [firstRender, setFirstRender] = useState(true)
  const [isSponsoredProductsEnabled, setIsSponsoredProductsEnabled] =
    useState<boolean>(false)

  const [loadingFeatureFlag, setLoadingFeatureFlag] = useState<boolean>(true)

  const extractBreadcrumbInfo = (breadcrumb, index) => ({
    name: breadcrumb[index]?.name || '',
    id: breadcrumb[index]?.id || ''
  })

  const { name: departmentName, id: departmentId } = extractBreadcrumbInfo(
    breadcrumbServer,
    0
  )
  const { name: sectionName, id: sectionId } = extractBreadcrumbInfo(
    breadcrumbServer,
    1
  )
  const { name: categoryName, id: categoryId } = extractBreadcrumbInfo(
    breadcrumbServer,
    2
  )
  const { name: subcategoryName, id: subCategoryId } = extractBreadcrumbInfo(
    breadcrumbServer,
    3
  )

  const menuId = [departmentId, sectionId, categoryId, subCategoryId]
    .filter(Boolean)
    .join(':')

  const { data: bannerData, isLoading } = useBannerMonetizaDepartments({
    placementType: 'listagem',
    departmentName,
    sectionName,
    categoryName,
    subcategoryName,
    menuId
  })

  const banner = bannerData?.listagem && bannerData?.listagem[0]

  const { headers, params: sponsoredProductsParams } =
    formatSponsoredProductsHeadersParams({
      clientId,
      session,
      query: formatCategoryName(
        departmentName,
        sectionName,
        categoryName,
        subcategoryName
      ),
      context: 'category'
    })

  const onFocus = () => {
    fetchTimestamp()
  }

  const { data: dataSponsoredProducts, isLoading: loadingSponsoredProducts } =
    useQuery({
      queryKey: [
        'getSponsoredProducts',
        params.facet_filters || Number(params.page_number),
        isSponsoredProductsEnabled
      ],
      queryFn: () =>
        sponsoredProductsService.getSponsoredProducts(
          headers,
          sponsoredProductsParams
        ),
      enabled:
        hasSponsoredProductsByParams(params) && isSponsoredProductsEnabled,
      retry: false
    })

  async function fetchTimestamp() {
    useTimestamp.setLoading(true)
    const { data } = await axios.get(BACKEND_TIME_STAMP)
    useTimestamp.setTimestamp(data)
    useTimestamp.setLoading(false)
  }

  function handleSearchTermParam() {
    const { query } = router
    const searchTerm = query?.['search-term']
    const hasSearchTerm = !!searchTerm

    if (hasSearchTerm) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { 'search-term': _, ...queryWithoutSearchTerm } = query
      pushToDataLayer({
        'search-term': decodeURIComponent(searchTerm as string)
      })
      router.replace({ query: queryWithoutSearchTerm }, undefined, {
        shallow: true
      })
    }
  }

  useEffect(() => {
    handleSearchTermParam()
    let decodedFilters: {}
    const { facet_filters } = data.params

    if (facet_filters) {
      try {
        decodedFilters = JSON.parse(`${base64Decode(facet_filters)}`)
      } catch (_e) {
        decodedFilters = {}
      }
    }

    init({
      params: data.params,
      products: data.products,
      filters: decodedFilters,
      listingType: type
    })
    fetchTimestamp()
  }, [])

  useEffect(() => {
    window.onpopstate = () => {
      const queryString = getParamsFromQueryString(
        window.location.search
      ) as Record<string, string>

      setParams(
        {
          facet_filters: getValueOrDefault(queryString?.facet_filters, ''),
          sort: getValueOrDefault(queryString?.sort, 'most_searched'),
          page_size: getValueOrDefault(Number(queryString?.page_size), 20),
          page_number: getValueOrDefault(Number(queryString.page_number), 1),
          is_prime: isPrime
        },
        FilterType.BACK
      )
    }

    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  async function verifySponsoredProductsFeatureEnabled(session: string) {
    const sponsoredProductsVariation =
      await getSponsoredProductsVariation(session)
    setIsSponsoredProductsEnabled(sponsoredProductsVariation)
    setLoadingFeatureFlag(false)
  }

  useEffect(() => {
    verifySponsoredProductsFeatureEnabled(session)
  }, [validSession, session])

  useDidUpdateEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    } else {
      fetchCatalog()
    }
  }, [params])

  function isLoadingSponsoredProducts() {
    return loadingFeatureFlag || loadingSponsoredProducts
  }

  async function fetchCatalog() {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    setLoading(true)

    let catalogData: CatalogType

    try {
      const paramsWithoutFilters = {
        ...params,
        payload_data: 'products_category_filters'
      }

      catalogData = await CatalogService.productsByCategory(
        slug,
        paramsWithoutFilters
      )
      setCatalog(catalogData)
    } catch (_e) {
      setNoResults(true)
    } finally {
      const isBackButton = filterPressed === FilterType.BACK
      if (!isBackButton) {
        const isPagination = filterPressed === FilterType.PAGINATION
        const queryString = getParamsFromQueryString(
          window.location.search
        ) as Record<string, string>

        CatalogService.replaceUrlParams(
          new URLSearchParams(Object.assign(queryString, params)),
          isPagination
        )
      }

      setLoading(false)
    }
  }

  const dataLayerProductClick = (product: Product): void => {
    const productFormatted = [
      {
        name: product.name,
        id: product.code.toString(),
        price: product.price,
        brand: product.manufacturer.name,
        category: product.category,
        position: product.position,
        dimension20: product.sellerId,
        dimension21: product.sellerName
      }
    ]
    dataLayerEventProductClick({ products: productFormatted, list: pagelist })

    if (product?.newtail) {
      sendBeaconData(
        product?.newtail.clickUrl,
        formatSendBeaconData(clientId, session)
      )
    }
  }

  if (noResults) {
    return <PageCatalogEmpty cookieIsMobile={cookieIsMobile} />
  }

  return (
    <>
      <PageHead
        title={seo?.title}
        metaTitle={seo?.titleHeading}
        metaDescription={seo?.description}
        metaImage={banners?.catalogList?.mediaUrl || banners?.main?.banner}
        hasMetaTwitter
        hasCanonical
        hasMetaOg
      >
        {!!catalog && (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: getGtmSchemaCatalogo(catalog.data, slug)
            }}
          />
        )}
      </PageHead>

      <Script id='SmartHint_PageType' type='application/javascript'>
        {`const SmartHint_PageType = "category";`}
      </Script>

      <PageCatalog
        bannersAds={banner}
        isLoadingBanner={isLoading}
        type={type}
        banners={banners}
        categories={categories}
        onProductClick={dataLayerProductClick}
        sponsoredProducts={dataSponsoredProducts}
        loadingSponsoredProducts={isLoadingSponsoredProducts()}
        catalogServer={catalogServer}
        breadcrumbServer={breadcrumbServer}
        pagelist={pagelist}
        titleHeading={seo?.titleHeading}
        cookieIsMobile={cookieIsMobile}
      />
    </>
  )
}

export async function getServerSideProps(context) {
  const { req, res, query, resolvedUrl } = context

  if (query.categoria.includes('_next')) {
    res.writeHead(404, {
      Location: '/',
      'Cache-Control': 'no-cache'
    })

    res.end()
    return { props: {} }
  }

  try {
    const isMobile = req.cookies?.isMobile === 'true'
    const is_prime = req.cookies?.CP_1286400 === 'true'

    let data = {}
    const [dep, sec, cat, sub] = query.categoria || []
    const categorias = {
      dep,
      sec,
      cat,
      sub
    }
    const slug = Object.values(categorias)
      .filter((item) => !!item)
      .join('/')

    const {
      page_number = 1,
      page_size = isMobile ? 10 : 20,
      facet_filters = '',
      sort = 'most_searched'
    } = query

    const params = {
      page_number,
      page_size,
      facet_filters,
      sort,
      is_prime,
      payload_data: isMobile
        ? 'products_category_filters'
        : 'products_all_filters'
    }

    try {
      const catalogData = await CatalogService.productsByCategory(slug, params)

      const currentPath = resolvedUrl.split('?')[0]
      const redirectPath = catalogData?.redirect?.split('?')[0]
      const shouldRedirect = redirectPath && redirectPath !== currentPath
      if (shouldRedirect) {
        delete query.categoria
        let queryString = new URLSearchParams(query).toString()
        if (queryString) {
          queryString = `?${queryString}`
        }

        res.writeHead(301, {
          Location: `${redirectPath}${queryString}`,
          'Cache-Control': 'no-cache'
        })

        res.end()
        return { props: {} }
      }
      data = {
        ...data,
        catalogServer: catalogData,
        breadcrumbServer: catalogData?.meta?.breadcrumb || [],
        seo: catalogData?.meta?.seo
      }

      const { breadcrumb } = catalogData?.meta || {}
      const bannerMenuCode = breadcrumb[breadcrumb.length - 1]?.id

      const bannersPromise = getBannersCategories(bannerMenuCode)
      const categoriesPromise = Categories.fetchCategories(slug, breadcrumb)

      const asidePromises = await Promise.allSettled([
        bannersPromise,
        categoriesPromise
      ])

      const [bannersData, categoriesData] = asidePromises.map(
        (promisesResponse) =>
          promisesResponse.status === 'fulfilled' ? promisesResponse.value : []
      )

      const banners = {
        main: bannersData?.data?.main || null,
        sidebar: bannersData?.data?.sidebar || null
      }

      data = {
        ...data,
        banners,
        categories: categoriesData
      }
    } catch (e) {
      if (e?.content?.response?.status === 404) {
        return {
          notFound: true
        }
      }
    }
    data = {
      ...data,
      slug,
      params,
      type: 'listing',
      pagelist: `categoria-${slug}`,
      cookieIsMobile: isMobile
    }

    data = JSON.stringify(data)
    res.setHeader('Cache-Control', 'public, max-age=20')
    return { props: { data } }
  } catch (e) {
    axiosError(e, res)
  }
}
